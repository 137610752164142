main {
    background: #000220;
    height: 100%;
    color: white;
    padding: 10px 20px;
}

nav {
    background: red;
    position: relative;
    width: 45%;
    height: 100%;
    display: none;
}

.event-list {
    margin-top: 20px;
}

.event-list li {
    background-color: gray;
    padding: 5px 10px;
    border-radius: 10px;
    height: 5em;
    margin-top: 1em;
    margin-bottom: 1em;
    cursor: pointer;
}

.event-list li:hover {
    background-color: darkgray;
}